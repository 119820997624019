export const FormStatus = {
	DRAFT: 1,
	PUBLISHED: 2,
	ARCHIVED: 3,
	DELETED: 99,
};

export const FormStatusLabel = {
	[FormStatus.DRAFT]: 'Draft',
	[FormStatus.PUBLISHED]: 'Published',
	[FormStatus.ARCHIVED]: 'Archived',
	[FormStatus.DELETED]: 'Deleted',
};

export const FormType = {
	MIXED: 1,
	STANDALONE: 2,
	INTEGRATED: 3,
};

export const FormTypeLabel = {
	[FormType.MIXED]: 'Mixed',
	[FormType.STANDALONE]: 'Standalone',
	[FormType.INTEGRATED]: 'Integrated',
};

export const FormRequestStatus = {
	AWAITING_ACTIVATION: 1,
	ACTIVATED: 2,
	IN_PROGRESS: 3,
	REVIEWING: 4,
	SUBMITTED: 5,
	REVISING: 6,
	CLOSED: 7,
	ARCHIVED: 8,
	DELETED: 99,
};

export const FormRequestStatusLabel = {
	[FormRequestStatus.AWAITING_ACTIVATION]: 'Awaiting activation',
	[FormRequestStatus.ACTIVATED]: 'Activated',
	[FormRequestStatus.IN_PROGRESS]: 'In progress',
	[FormRequestStatus.REVIEWING]: 'Reviewing',
	[FormRequestStatus.SUBMITTED]: 'Submitted',
	[FormRequestStatus.REVISING]: 'Revising',
	[FormRequestStatus.CLOSED]: 'Closed',
	[FormRequestStatus.ARCHIVED]: 'Archived',
	[FormRequestStatus.DELETED]: 'Deleted',
};
